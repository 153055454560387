import React from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import { Helmet } from "react-helmet";
import Invite from "../components/InviteComponent";
import withRoot from "../withRoot";
import qs from "query-string";
import request from "superagent";

const styles = theme => ({
  root: {
    textAlign: "center",
    paddingTop: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white"
  },
  InviteWrapper: {
    display: "flex",
    justifyContent: "center",
    width: "100%"
  },
  Buttons: {
    marginBottom: "32px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
  },
  Button: {
    marginLeft: theme.spacing(1),
    borderRadius: 0
  },
  Details: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(3)
    }
  }
});

class SaveTheDate extends React.Component {
  constructor() {
    super();
    this.state = {
      responded: false
    };
  }
  componentDidMount() {
    const queryString = qs.parse(this.props.location.search);
    const guest = queryString.hash;
    request
      .get(
        `https://s3g1w1nqxd.execute-api.us-east-1.amazonaws.com/dev/guest-action/?action=SAVE_VIEW&hash=${guest}`
      )
      .then(res => {
        console.log(res);
      })
      .catch(err => console.log(err));
  }
  handleClickCan() {
    const queryString = qs.parse(this.props.location.search);
    const guest = queryString.hash;
    request
      .get(
        `https://s3g1w1nqxd.execute-api.us-east-1.amazonaws.com/dev/guest-action/?action=SAVE_YES&hash=${guest}`
      )
      .then(res => {
        console.log(res);
        this.setState({
          responded: true
        });
      })
      .catch(err => console.log(err));
  }

  handleClickCant() {
    const queryString = qs.parse(this.props.location.search);
    const guest = queryString.hash;
    request
      .get(
        `https://s3g1w1nqxd.execute-api.us-east-1.amazonaws.com/dev/guest-action/?action=SAVE_NO&hash=${guest}`
      )
      .then(res => {
        console.log(res);
        this.setState({
          responded: true
        });
      })
      .catch(err => console.log(err));
  }

  renderButtons() {
    const { classes, width } = this.props;
    const { responded } = this.state;
    if (responded)
      return (
        <div className={classes.Buttons}>
          <Button color="primary" disabled>
            thanks
          </Button>
        </div>
      );
    return (
      <div className={classes.Buttons}>
        <div className={classes.Button}>
          <Button
            variant="outlined"
            color="primary"
            onClick={this.handleClickCan.bind(this)}
            size={width === "lg" || width === "md" ? "medium" : "small"}
          >
            Hopefully can
          </Button>
        </div>
        <div className={classes.Button}>
          <Button
            variant="outlined"
            color="primary"
            size={width === "lg" || width === "md" ? "medium" : "small"}
            onClick={this.handleClickCant.bind(this)}
          >
            Definitely can't
          </Button>
        </div>
      </div>
    );
  }

  render() {
    const queryString = qs.parse(this.props.location.search);
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Save the date</title>
        </Helmet>
        <div className={classes.InviteWrapper}>
          <Invite name={queryString.name || null} />
        </div>
        <div className={classes.Details}>
          <Typography variant="body1">Saturday, March 7th, 2020</Typography>
          <Typography variant="body1">
            Capilla Santa Teresita, then Parador La Huella
          </Typography>
          <Typography variant="body1" gutterBottom>
            José Ignacio, Uruguay
          </Typography>
          {this.renderButtons.call(this)}
        </div>
      </div>
    );
  }
}

export default withRoot(withWidth()(withStyles(styles)(SaveTheDate)));
