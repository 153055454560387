import React, { Component } from "react"; // eslint-disable-line no-unused-vars
import { withStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import InviteFront from "./InviteFront";
import InviteBack from "./InviteBack";
import Invite from "./Invite";
import EnvelopeBack from "./EnvelopeBack";
import CardShadow from "./CardShadow";

const imageRatio = 1.2970521542;
const baseWidth = 280;
const wideWidth = 450;

const styles = theme => ({
  InviteComponent: {
    display: "flex",
    justifyContent: "center",
    transform: "translate3d(0,0,0)"
  },
  FlipBox: {
    backgroundColor: "transparent",
    position: "relative",
    width: `${baseWidth}px`,
    height: `${baseWidth * imageRatio}px`,
    perspective: "1000px",
    [theme.breakpoints.up("md")]: {
      width: `${wideWidth}px`,
      height: `${wideWidth * imageRatio}px`
    }
  },
  FlipBoxInner: {
    position: "relative",
    width: "100%",
    height: "100%",
    textAlign: "center",
    transition: "transform 1s",
    transformStyle: "preserve-3d"
  },
  FlipBoxInnerFlip: {
    transform: "translateX(-100%) rotateY(180deg)",
    [theme.breakpoints.up("md")]: {
      transform: "translateX(-200%) rotateY(180deg)"
    }
  },
  FlipBoxFront: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backfaceVisibility: "hidden"
  },
  FlipBoxBack: {
    position: "absolute",
    right: 0,
    width: "172%",
    height: "100%",
    backfaceVisibility: "hidden",
    transform: "rotateY(180deg) translateZ(1px)"
  },
  Invite: {
    position: "absolute",
    right: "0",
    top: "5%",
    width: "100%",
    height: "100%",
    backfaceVisibility: "hidden",
    opacity: 1,
    transform: "rotateY(180deg) translateZ(2px)",
    transition: "opacity 1s, transform 1s"
  },
  InviteMoved: {
    transform: "translateX(-100%) rotateY(180deg) translateZ(2px)",
    transition: "opactity 1s, transform 1s",
    transitionDelay: "1s",
    opacitiy: 0.5,
    [theme.breakpoints.up("md")]: {
      transform: "translateX(-200%) rotateY(180deg) translateZ(2px)"
    }
  },
  Envelope: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backfaceVisibility: "hidden",
    transform: "rotateY(180deg) translateZ(3px)"
  },
  Shadow: {
    overflow: "hidden",
    right: "-20px",
    top: 0,
    height: "91.5%",
    position: "absolute",
    width: "20px",
    opacity: 0,
    visibility: "hidden",
    [theme.breakpoints.up("md")]: {
      width: "27px",
      right: "-27px"
    }
  },
  ShadowShow: {
    opacity: "1",
    visibility: "visible",
    transition: "opacity 1s",
    transitionDelay: "1s"
  },
  EnvelopeName: {
    position: "absolute",
    top: "50%",
    width: "90%",
    left: "5%",
    fontFamily: "Caveat",
    color: "#606060",
    fontSize: "32px",
    [theme.breakpoints.up("md")]: {
      fontSize: "64px"
    }
  }
});

class InviteComponent extends Component {
  state = {
    flip: false,
    moved: false
  };

  componentDidMount() {
    window.setTimeout(this.toggleFlip.bind(this), 1000);
  }

  toggleFlip() {
    const { flip, moved } = this.state;
    this.setState({
      flip: !flip,
      moved: !moved
    });
  }

  render() {
    const { classes } = this.props;
    const { flip, moved } = this.state;
    return (
      <div className={classes.InviteComponent}>
        <div className={classes.FlipBox} onClick={this.toggleFlip.bind(this)}>
          <div
            className={classnames(classes.FlipBoxInner, {
              [classes.FlipBoxInnerFlip]: flip,
              [classes.FlipBoxInnerMoved]: moved
            })}
          >
            <div className={classes.FlipBoxFront}>
              <InviteFront />
              <span className={classes.EnvelopeName}>
                {this.props.name || null}
              </span>
            </div>
            <div className={classes.FlipBoxBack}>
              <InviteBack />
            </div>
            <div
              className={classnames(classes.Invite, {
                [classes.InviteMoved]: moved
              })}
            >
              <Invite />
              <div
                className={classnames(classes.Shadow, {
                  [classes.ShadowShow]: moved
                })}
              >
                <CardShadow />
              </div>
            </div>
            <div className={classes.Envelope}>
              <EnvelopeBack />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(InviteComponent);
